import React from 'react';
import { useAxiosGet } from '../../../configs/axios';
import { Table, Tag } from 'antd';

function AgentCompensate({ selectedOrder }) {

  const {
    data: agentCompensateData,
    loading: agentCompensateLoading,
  } = useAxiosGet('compensate/agentCompensations', {
    autoRun: true,
    orderId: selectedOrder.orderId
  });


  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'od',
    },
    {
      title: 'Agent Name',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
      render: (text) => {
        switch (text) {
          case 'lezzoo':
            return <Tag color="red">Lezzoo</Tag>;
          case 'merchant':
            return <Tag color="blue">Merchant</Tag>;

          default:
            return <Tag>{text}</Tag>;
        }
      },
    },
    {
      title: 'Action Type',
      dataIndex: 'actionType',
      key: 'actionType',
      render: (text) => {
        switch (text) {
          case 'refunds':
            return <Tag color="red">refunds</Tag>;
          case 'compensations':
            return <Tag color="blue">compensations</Tag>;

          default:
            return <Tag>{text}</Tag>;
        }
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];

  return (
    <div>
      <Table
        loading={agentCompensateLoading}
        dataSource={agentCompensateData}
        columns={columns}
      />
    </div>
  );
}

export default AgentCompensate;
