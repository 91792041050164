import { lazy } from 'react';
import SupportTeamMetrics from './pages/supportTeamMetrics/SupportTeamMetrics';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Permission = lazy(() => import('./pages/Permission'));
const Chat = lazy(() => import('./pages/Chat'));
const Chatv2 = lazy(() => import('./pages/Chatv2'));
const CustomerChat = lazy(() => import('./pages/CustomerChat'));
const Users = lazy(() => import('./pages/Users'));
const FreeMeal = lazy(() => import('./pages/FreeMeal'));
const SendLezzooPay = lazy(() => import('./pages/SendLezzooPay'));
const CustomerAddress = lazy(() => import('./pages/CustomerAddress'));
const PredefinedMessages = lazy(() => import('./pages/PredefinedMessages'));
const Customers = lazy(() => import('./pages/Customers'));
const Broosk = lazy(() => import('./pages/Broosk'));
const MerchantHubSort = lazy(() => import('./pages/MerchantHubSort'));
const MerchantHubClose = lazy(() => import('./pages/MerchantHubClose'));
const Vouchers = lazy(() => import('./pages/Vouchers'));
const HubClosure = lazy(() => import('./pages/HubClosure'));
const VipCrud = lazy(() => import('./pages/VipCrud'));
const QA = lazy(() => import('./pages/QA'));
const RequestRefunds = lazy(() => import('./pages/RequestRefunds'));
const QAOrders = lazy(() => import('./pages/QAOrders'));
const OrderPeak = lazy(() => import('./pages/OrderPeak'));
const OrderWithMap = lazy(() => import('./pages/OrderWithMap'));
const TaskManager = lazy(() => import('./pages/TaskManager'));
const BlockCustomers = lazy(() => import('./pages/BlockCustomers'));
const LezzooGames = lazy(() => import('./pages/LezzooGames'));
const DriverShifts = lazy(() => import('./pages/DriverShifts'));
const DriverPenalty = lazy(() => import('./pages/DriverPenalty'));
const DriverCashOut = lazy(() => import('./pages/DriverCashOut'));
const DriverRank = lazy(() => import('./pages/DriverRank'));
const LezzooBoardManager = lazy(()=> import('./pages/LezzooBoardManager'))
const MyTasks = lazy(()=> import('./pages/MyTasks'))
const options = [
  {
    key: Math.random(),
    path: '/',
    component: Home,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/permissions',
    component: Permission,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/chat',
    component: Chat,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/Chatv2',
    component: Chatv2,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/chat-customer',
    component: CustomerChat,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/user-status',
    component: Users,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/free-meals',
    component: FreeMeal,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/send-lezzoopay',
    component: SendLezzooPay,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/customer-address',
    component: CustomerAddress,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/customers',
    component: Customers,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/predefined-messages',
    component: PredefinedMessages,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/broosk',
    component: Broosk,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/merchant-hub-close',
    component: MerchantHubClose,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/merchant-hub-sort',
    component: MerchantHubSort,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/vouchers',
    component: Vouchers,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/hub-closure',
    component: HubClosure,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/vip-merchants',
    component: VipCrud,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/quality-assurance',
    component: QA,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/request-refunds',
    component: RequestRefunds,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/support-team-metrics',
    component: SupportTeamMetrics,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/QA-orders',
    component: QAOrders,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/chatv2',
    component: Chatv2,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/OrderPeak',
    component: OrderPeak,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/OrderWithMap',
    component: OrderWithMap,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/taskManager',
    component: TaskManager,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/block-customer',
    component: BlockCustomers,
    exact: true,
  },


  {
    key: Math.random(), 
    path: '/lezzoo-games',
    component: LezzooGames,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/driver-shifts',
    component: DriverShifts,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/driver-penalty',
    component: DriverPenalty,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/driver_cash_out',
    component: DriverCashOut,
    exact: true,
  },


  {
    key: Math.random(),
    path: '/LezzooBoard',
    component: LezzooBoardManager,
    exact: true,
  },
  {
    key: Math.random(),
     path: '/driver_rank',
    component: DriverRank,
    exact: true,
  },


  {
    key: Math.random(),
    path: '/MyTasks',
    component: MyTasks,
    exact: true,

  }

];
export default options;
