import { Table, Typography } from 'antd';
import React from 'react';
import AgentList from './ components/AgentList';

function SupportTeamMetrics() {
  return (
    <div style={{
      padding: 24,
      minHeight: 360,

    }}>
      <Typography.Title level={2} style={{

      alignSelf: 'center',
      textAlign: 'center',
      }}>Support Team Metrics</Typography.Title>
      <AgentList />
    </div>
  );
}

export default SupportTeamMetrics;
