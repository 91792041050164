import _ from 'lodash';
import { axiosChatInstance, useAxiosGetChat } from '../../configs/axios';
import supportTeamMetricsStates from './States';
import { Alert } from 'antd';

const getSupportTeamMetrics = async (searchQuery) => {
  try {
    // supportTeamMetricsStates.dataState.loading.set(true);
    supportTeamMetricsStates.dataState.nested('error').set(null);

    // Include search query in the request if provided
    const params = searchQuery ? { search: searchQuery } : {};
    const response = await axiosChatInstance
      .get('/agents/agentMatrix', { params })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
    supportTeamMetricsStates.dataState.merge({
      loading: false,
      error: null,
      data: response,
      tabs: response.map((tab) => ({
        key: tab.tabKey,
        tab: tab.tabKey,
        count: tab.agents.length?tab.agents.length-1:tab.agents.length,
      })),
    });

    selectTab(supportTeamMetricsStates.dataState.selectedTab.get() || 'All');
  } catch (err) {
    supportTeamMetricsStates.dataState.merge({
      loading: false,
      error: err,
    });
  }
};


const selectTab = (tabKey) => {
  if (
    !tabKey ||
    !supportTeamMetricsStates.dataState.tabs
      .get()
      .find((tab) => tab.tab === tabKey)
  )
    return;

  supportTeamMetricsStates.dataState.merge((e) => ({
    selectedTab: tabKey,
    filteredData: e.data.find((tab) => tab.tabKey === tabKey)?.agents || [],
  }));
};

const changeAgentRole = async (agentId, agentType) => {
  try {
    const response = await axiosChatInstance
      .put(`/agents/agentType`, {
        agentId,
        agentType,
      })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
    return response;
  } catch (err) {
    throw err;
  }
};

export const supportTeamMetricsFunctions = {
  getSupportTeamMetrics,
  selectTab,
  changeAgentRole,
};

export default supportTeamMetricsFunctions;
