import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    console.error('Error Boundary caught an error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render a nice error message with detailed error information
      return (
        <div style={styles.errorContainer}>
          <h1 style={styles.errorHeading}>Something went wrong...</h1>
          <p style={styles.errorMessage}>
            We're sorry, but something unexpected occurred.
          </p>

          {process.env.NODE_ENV === 'development' && (
            <p style={styles.errorMessage}> Below is the detailed error:</p>
          )}

          {process.env.NODE_ENV === 'development' && (
            <div style={styles.errorDetails}>
              <p>{this.state.error && this.state.error.toString()}</p>
              <pre>{this.state.errorInfo.componentStack}</pre>
            </div>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

const styles = {
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Make the container full height of the viewport
    textAlign: 'center',
    padding: '20px',
  },
  errorHeading: {
    fontSize: '2rem',
    color: '#FF0000',
  },
  errorMessage: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: '20px',
  },
  errorDetails: {
    textAlign: 'left',
    marginTop:`20px`
    
  },
};

export default ErrorBoundary;
