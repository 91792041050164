import React, { useState} from 'react';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { useAxiosGet, useAxiosPost } from '../../configs/axios';
import DebounceInput from '../utility/DebounceInput';


const VIPButton = () => {
    const { request: getAllCustomers } = useAxiosGet("/customers");
    const [makeCustomerVIPModalVisible, setMakeCustomerVIPModalVisible] =
    useState(false);
    const [customerSearchValue, setCustomerSearchValue] = useState("");

    const { loading: vipLoading, request: makeCustomerVip ,error:vipErr} =
    useAxiosPost('/vip');

    const [vipForm] = Form.useForm();

    const fetchCustomersDropdown = async (searchQuery) => {
        const response = await getAllCustomers({ search: searchQuery });
    
        return response.map((customer) => ({
          label: `${customer.customer_id} - ${customer.customer_name} - ${customer.customer_phone}`,
          value: JSON.stringify(customer),
        }));
      };

      const onVipFormConfitm = async (values) => {
        try {
          const customer = JSON.parse(values.customerId[0].value).customer_id
          const tierId = values.tierId.value
          const orderId = values.orderId
          await makeCustomerVip({
            customerId: customer,
            tierId: tierId,
            orderId: orderId
          })
          message.success('Customer subscribed to VIP successfully');
          setMakeCustomerVIPModalVisible(false);
          vipForm.resetFields();
        } catch (e) {
          if(vipErr?.response?.data?.msg){
            message.error(vipErr.response.data.msg);
            return
          }
          message.error('Error subscribing VIP customer');
        }
      };

    return (
      <>
        <Button
        size="large"
        type="primary"
        style={{
          borderRadius: '60px',
        }}
        onClick={() => setMakeCustomerVIPModalVisible(true)}
      >
        Make Customer VIP
      </Button>

      <Modal
        title="Make Customer VIP"
        destroyOnClose
        open={makeCustomerVIPModalVisible}
        onOk={() => {
          vipForm.submit();
        }}
        okButtonProps={{
          loading: vipLoading,
        }}
        onCancel={() => {
          setMakeCustomerVIPModalVisible(false);
        }}
      >
        <Form
          loading={true}
          form={vipForm}
          layout="vertical"
          onFinish={onVipFormConfitm}
        >

          <Form.Item name="customerId" >
            <DebounceInput
              value={customerSearchValue}
              placeholder="Filter by customer"
              fetchOptions={fetchCustomersDropdown}
              onChange={(newValue) => {
                if (newValue?.length > 1) {
                  return newValue.pop();
                }

                setCustomerSearchValue(newValue);

              }}
            />
          </Form.Item>

          <Form.Item
            name="tierId"
            label="Tier Id"
            rules={[
              {
                required: true,
                message: "Please input amount",
              },
              {
                validator: (_, value) => {
                  if (value > 5) {
                    return Promise.reject(
                      new Error("Amount must be less than 5")
                    );
                  }
                  if (value < 0) {
                    return Promise.reject(
                      new Error("Amount must be greater than 0")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              labelInValue
              filterOption={false}
              options={[
                {
                  label: "Eco",
                  value: 4,
                },
                {
                  label: "Standard",
                  value: 1,
                },
                {
                  label: "3 Month Standard",
                  value: 2,
                },
                {
                  label: "1 Year Standard",
                  value: 3,
                }
              ]}
            />
          </Form.Item>
          <Form.Item
            name="orderId"
            label="Order Id"
            rules={[
              {
                required: false,
                message: "Please input order id",
              },

            ]}
          >
            <Input type="number" placeholder="E.g. 134..." />
          </Form.Item>

        </Form>
      </Modal>
      </>
    )
}

export default VIPButton