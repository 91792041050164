import React, { useState, useEffect } from 'react';
import { Select, Spin, Empty, Form } from 'antd';
import { useAxiosGet } from './../../configs/axios';
import debounceInput from './../../functions/debounceFx';
const RemoteSelectForm = ({
  labelForm, //Label for <Form.Item />
  nameForm, //Name for <Form.Item />
  placeholder,
  disabled,
  className,
  rtl,
  endpoint,
  mode,
  optionText,
  optionValue,
  exclude,
  addExtraData,
  labelCol,
  onValueChange, //onChange function
  allowClear,
  valueOption, //Pass specific value for <Option value='ID'></Option>
  labelOption, // pass just label for <Option>{ label }</Option>
  searchOption, // pass specific value for query instead of search
  filterFromServer,
  query, //* we using query while we need some params. depend backend developer.
  dependency, //* we need while we want re fetch and rebuilding UI
  rules, // rules for <Form.Item />
  labelInValue=true,
  fetchAxios = useAxiosGet,
  style,
}) => {
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [value, setValue] = useState();

  const { request, loading } = fetchAxios(endpoint, {
    offset: 0,
    limit: 100,
  });

  useEffect(() => {
    if (endpoint) {
      loadData();
    }
  }, dependency || []);

  const onChange = (object) => {
    setValue(object);
    try {
      const key = object ? object.value : false;
      const objectLabel = apiData.find((d) => d[valueOption] == key);
      onValueChange({ key, object, objectLabel });
    } catch (e) {
      //
    }
  };

  const onSearch = (searchQuery) => {
    loadData(searchQuery);
  };

  const loadData = async (searchQuery) => {
    await request({
      limit: 100,
      offset: 0,
      [searchOption || 'search']: searchQuery,
      ...query,
    })
      .then((res) => {
        let array = [];
        if (exclude) {
          const filtered = res.filter(
            (value) => value[valueOption] !== exclude,
          );
          array = filtered;
        } else {
          array = res;
          if (!Array.isArray(array)) {
            array = res;
          }
        }

        const options = [];
        array.forEach((element) => {
          options.push({
            label:
              typeof optionText == 'function'
                ? optionText(element)
                : element[labelOption],
            value:
              typeof optionValue == 'function'
                ? optionValue(element)
                : element[valueOption],
          });
        });
        setData(options);
        setApiData(array);
      })
      .catch(() => {
        setData([]);
      });
  };

  const onSearchDebounce = debounceInput((e) => onSearch(e));

  return (
    <Form.Item
      style={style || {}}
      rules={rules}
      name={nameForm}
      label={labelForm}
      labelCol={labelCol}
    >
      <Select
        style={style || { width: '100%' }}
        allowClear={allowClear}
        mode={mode || null}
        showSearch
        labelInValue={labelInValue}
        disabled={disabled}
        value={value}
        notFoundContent={
          loading ? (
            <Spin size="small" />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        filterOption={
          !filterFromServer
            ? (input, option) => {
                return (option?.children ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
            : false
        }
        onSearch={filterFromServer ? onSearchDebounce : undefined}
        onChange={onChange}
        placeholder={placeholder || ''}
        className={className || undefined}
      >
        {(addExtraData && addExtraData.length > 0 && addExtraData[0].value !== 0
          ? addExtraData.concat(data)
          : data
        ).map((val) => (
          <Select.Option
            key={val.value}
            value={val.value}
            style={{ fontFamily: rtl ? 'kurdishFont' : undefined }}
          >
            {val.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default RemoteSelectForm;

/**
 * 
 * <RemoteSelectForm
      labelForm="Payment"
      nameForm="payment_type_id"
      allowClear={false}
      filterFromServer={false}
      endpoint="/paymentTypes/list"
      valueOption="payment_type_id"
      labelOption="payment_type_name"
  />
 */
