import { Layout } from 'antd';
import React, { createContext, Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { Provider } from 'react-redux';
import _ from 'lodash';
import routes from './routes';
import SidebarContent from './components/layout/SidebarContent';
import HeaderContent from './components/layout/HeaderContent';
import Login from './pages/Login';
import Order from './pages/Order';
import useLocalStorage from './configs/localStorage';
import store from './store';
import ErrorBoundary from './ErrorBoundary';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import
// @ts-ignore
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { Header, Sider, Content } = Layout;

function App() {
  const [auth, setAuth] = useLocalStorage('auth', null);
  if (!auth?.user?.role?.permissions && auth != null) {
    setAuth(null);
  }
  const pers = auth?.user.role.permissions.map((per) => per.name);

  _.remove(routes, (route) => !_.includes(pers, route.path));

  const [collapsed, setCollapsed] = React.useState(true);
  if (window.location.pathname === '/login') return <Login />;
  if (window.location.pathname === '/order-details') return <Order />;
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <Layout
            style={{
              minHeight: '100vh',
            }}
          >
            <Sider
              collapsed={collapsed}
              style={{
                zIndex: 1,
                background: '#fff',
                height: '100vh',
                position: 'fixed',
                overflow: 'auto',
                boxShadow: '0.4px 0.4px 20px #bfbdb644',
              }}
            >
              <SidebarContent />
            </Sider>
            <Layout>
              <Header
                style={{
                  background: 'white',
                  position: 'fixed',
                  zIndex: 1000,
                  width: collapsed ? 'calc(100% - 79px)' : 'calc(100% - 200px)',
                  marginLeft: collapsed ? '79px' : '200px',
                  transition: 'all 0.2s',
                  paddingInline: '10px',
                }}
              >
                <HeaderContent toggleSidebar={() => setCollapsed(!collapsed)} />
              </Header>
              <Content
                style={{
                  background: '#F8FBFF',
                  marginLeft: collapsed ? '79px' : '200px',
                  padding: '0px 10px',
                  marginTop: '64px',
                }}
              >
                <Routes>
                  {routes.map((route) => (
                    <Route
                      path={`${route.path}`}
                      key={route.key}
                      element={
                        <Suspense fallback={<>...</>}>
                          <route.component />
                        </Suspense>
                      }
                    />
                  ))}
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
