import { useHookstate } from '@hookstate/core';
import { Alert, Button, Modal, Typography } from 'antd';
import React, { useEffect } from 'react';
import supportTeamMetricsStates from '../States';
import supportTeamMetricsFunctions from '../SupportTeamMetricsFunctions';

function RoleModal() {
  const modalState = useHookstate(supportTeamMetricsStates.modalState);
  const tabs = useHookstate(supportTeamMetricsStates.dataState.tabs);
  const [selectedRole, setSelectedRole] = React.useState(null);
  useEffect(() => {
    setSelectedRole(
      modalState.data.get()?.agentType ||
        supportTeamMetricsStates.dataState.selectedTab.get(),
    );
  }, [
    modalState.data.get()?.agentType,
    supportTeamMetricsStates.dataState.selectedTab.get(),
  ]);

  const onOk = async () => {
    await supportTeamMetricsFunctions
      .changeAgentRole(modalState.data.get()?.agentId, selectedRole)
      .then((d) => {
        modalState.visible.set(false);
        supportTeamMetricsFunctions.getSupportTeamMetrics();
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  return (
    <Modal
      title={`${modalState.data.get()?.agentId} ${
        modalState.data.get()?.agentName
      }`}
      open={modalState.visible.get()}
      onCancel={() => modalState.visible.set(false)}
      closable
      onOk={async () => {
        await onOk();
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography.Title>{modalState.data.get()?.agentType}</Typography.Title>
        {/* tabs excluding all as buttons */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {tabs.get().map((tab) => {
            return tab.tab === 'All' ? null : (
              <Button
                type={tab.tab === selectedRole ? 'primary' : 'default'}
                key={tab.tab}
                onClick={async () => {
                  setSelectedRole(tab.tab);
                }}
              >
                {tab.tab}
              </Button>
            );
          })}
          <Button
            type={'SP' === selectedRole ? 'primary' : 'default'}
            key={'SP'}
            onClick={async () => {
              setSelectedRole('SP');
            }}
          >
            SP
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RoleModal;
