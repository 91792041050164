import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import {CHAT_URL,API_URL_TASK_OPERATION} from './constants';
import API_URL from './constants';

const auth = localStorage.getItem('auth');

if (!auth && window.location.pathname !== '/login') {
  window.location.href = '/login';
}

const authObject = JSON.parse(auth);

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    authorization: authObject ? `Bearer ${authObject.token}` : '',
  },
});


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401
      && window.location.pathname !== '/login'
    ) {
      localStorage.removeItem('auth');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export const useAxiosGet = (url, params = { autoRun: false }, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(url, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};


export const useAxiosGetV2 = (url, params = { autoRun: false }, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const lastParams = useRef(params);

  const request = async (queryParams = lastParams.current) => {
    const mergeQueryParams = {
      ...lastParams.current,
      ...queryParams
    };

    try {
      setLoading(true);
      // Update lastParams.current with the latest parameters
      lastParams.current = mergeQueryParams;
      
      const response = await axiosInstance.get(url, {
        params: mergeQueryParams,
        headers
      });
      setData(response.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
  
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data,
    setData,
    loading,
    error,
    request,
    lastParams: lastParams.current
  };
};


export const useAxiosDelete = (
  url,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params, deleteId = '') => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(`${url}/${deleteId}`, {
        params: queryParams,
        headers,

      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosPostV2 = (
  url,
  body,
  params = { autoRun: false },
  headers = {}
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const request = async (
    bodyParams = body,
    queryParams = params,
    headersParams = headers
  ) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(url, bodyParams, {
        params: queryParams,
        headersParams
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);

      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data,
    setData,
    loading,
    error,
    request
  };
};
export const useAxiosPost = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (
    bodyParams = body,
    queryParams = params,
    headersParams = headers,
  ) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(url, bodyParams, {
        params: queryParams,
        headersParams,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
     
      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosPut = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyParams = body, queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(url, bodyParams, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosPatch = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyParams = body, queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosInstance.patch(url, bodyParams, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};


// chat axios api

export const axiosChatInstance = axios.create({
  baseURL: CHAT_URL+'/api',
  headers: {
    authorization: authObject ? `Bearer ${authObject.token}` : '',
  },
});

export const useAxiosGetChat = (url, params = { autoRun: false }, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosChatInstance.get(url, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosChatDelete = (
  url,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params, deleteId = '') => {
    try {
      setLoading(true);
      const response = await axiosChatInstance.delete(url + deleteId, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosPostChat = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (
    bodyParams = body,
    queryParams = params,
    headersParams = headers,
  ) => {
    try {
      setLoading(true);
      const response = await axiosChatInstance.post(url, bodyParams, {
        params: queryParams,
        headersParams,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};
export const useAxiosChatPut = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyParams = body, queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosChatInstance.put(url, bodyParams, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};



export const axiosTaskManagementInstance = axios.create({
  baseURL: API_URL_TASK_OPERATION,
  headers: {
    authorization: authObject ? `Bearer ${authObject.token}` : '',
  },
});

export const useAxiosGetTaskManagement = (url, params = { autoRun: false }, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosTaskManagementInstance.get(url, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosTaskManagementDelete = (
  url,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params, deleteId = '') => {
    try {
      setLoading(true);
      const response = await axiosTaskManagementInstance.delete(url + deleteId, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosPostTaskManagement = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (
    bodyParams = body,
    queryParams = params,
    headersParams = headers,
  ) => {
    try {
      setLoading(true);
      const response = await axiosTaskManagementInstance.post(url, bodyParams, {
        params: queryParams,
        headersParams,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};
export const useAxiosTaskManagementPut = (
  url,
  body,
  params = { autoRun: false },
  headers = {},
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyParams = body, queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosTaskManagementInstance.put(url, bodyParams, {
        params: queryParams,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data, setData, loading, error, request,
  };
};

export const useAxiosTaskManagementGetV2 = (url, params = { autoRun: false }, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const lastParams = useRef(params);

  const request = async (queryParams = lastParams.current) => {
    const mergeQueryParams = {
      ...lastParams.current,
      ...queryParams
    };

    try {
      setLoading(true);
      // Update lastParams.current with the latest parameters
      lastParams.current = mergeQueryParams;
      
      const response = await axiosTaskManagementInstance.get(url, {
        params: mergeQueryParams,
        headers
      });
      setData(response.data);
      setError(null);
      return response.data;
    } catch (err) {
      setError(err);
  
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data,
    setData,
    loading,
    error,
    request,
    lastParams: lastParams.current
  };
};