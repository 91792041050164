import React, { useState } from 'react';
import {
  Button,
  Form,
  Spin,
  Input,
  InputNumber,
  Select,
  message,
} from 'antd';
import { useAxiosPost } from '../../../configs/axios';

const CompensationTab = ({ selectedOrder }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectOrderLoading, setSelectOrderLoading] = useState(false);
  const [currentRefund, setCurrentRefund] = useState(0);
  const [selectedReason, setSelectedReason] = useState('');
  const [selectedTarget, setSelectedTarget] = useState('');

  const { request: compensateRequest } = useAxiosPost('/compensate/compensate');

  const handleSubmit = async (values) => {
    setLoading(true);
    const newValues = {
      orderId: selectedOrder.orderId,
      ...values,
      description: selectedReason === 'other' ? values.description : selectedReason,
    };

    await compensateRequest(newValues);

    message.success('Compensation Done Successfully');
    setLoading(false);
  };


  const MerchantIssues = [
    { label: 'Bad Quality', value: 'bad-quality' },
    { label: 'Missing item', value: 'missing-item' },
    { label: 'Wrong item', value: 'wrong-item' },
    { label: 'Delay in preparation', value: 'preparation-delay' },
    { label: 'Item not available', value: 'item-not-available' },
    { label: 'Merchant not accepting order', value: 'merchant-not-accepting-order' },
    { label: 'Other', value: 'other' },
  ];

  const LezzooIssues = [
    { label: 'Satisfy customer', value: 'satisfy-customer' },
    { label: 'Paid online order canceled', value: 'online-order-canceled' },
    { label: 'Paid online order edited', value: 'online-order-edited' },
    { label: 'Technical issue', value: 'technical-issue' },
    { label: 'Delay in delivery due to load', value: 'delivery-delay-load' },
    { label: 'Other', value: 'other' },
  ];

  const DriverIssues = [
    { label: 'Delay in delivery', value: 'delivery-delay' },
    { label: 'Ruined/Damaged order', value: 'damaged-order' },
    { label: 'Bad behavior', value: 'bad-behavior' },
    { label: 'Driver did not have change', value: 'no-change' },
    { label: 'Driver took wrong order', value: 'wrong-order' },
    { label: 'Other', value: 'other' },
  ];

  return (
    <div>
      <Form onFinish={handleSubmit} form={form}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Form.Item label="Amount" name="amount">
            <InputNumber
              style={{
                width: '200px',
              }}
              min={1}
              placeholder="Amount"
              onChange={(e) => {
                setCurrentRefund(e);
              }}
            />
          </Form.Item>
          <Form.Item label="Target" name="target">
            <Select
              style={{
                width: '200px',
              }}
              placeholder="Target"
              onChange={(value) => setSelectedTarget(value)}
            >
              <Select.Option key="lezzoo" value="lezzoo">
                Lezzoo
              </Select.Option>
              <Select.Option key="merchant" value="merchant">
                Merchant
              </Select.Option>
              <Select.Option key="driver" value="driver">
                Driver
              </Select.Option>
            </Select>
          </Form.Item>
          {selectedTarget === 'merchant' && (
            <Form.Item label="Merchant Reasons" name="merchantReason">
              <Select
                style={{
                  width: '250px',
                }}
                placeholder="Reason"
                onChange={(value) => setSelectedReason(value)}
                options={MerchantIssues}
              />
            </Form.Item>
          )}
          {selectedTarget === 'lezzoo' && (
            <Form.Item label="Lezzoo Reasons" name="lezzooReason">
              <Select
                style={{
                  width: '250px',
                }}
                placeholder="Reason"
                onChange={(value) => setSelectedReason(value)}
                options={LezzooIssues}
              />
            </Form.Item>
          )}
          {selectedTarget === 'driver' && (
            <Form.Item label="Driver Reasons" name="driverReason">
              <Select
                style={{
                  width: '250px',
                }}
                placeholder="Reason"
                onChange={(value) => setSelectedReason(value)}
                options={DriverIssues}
              />
            </Form.Item>
          )}
          {selectedReason === 'other' && (
            <Form.Item label="Extra Notes" name="description">
              <Input style={{ width: 200 }} placeholder="Extra Notes" />
            </Form.Item>
          )}
          <Button
            loading={loading}
            type="primary"
            style={{ marginBottom: 22 }}
            htmlType="submit"
          >
            Refund
          </Button>
        </div>
      </Form>
      {selectedOrder?.totalOrderRefund + currentRefund > selectedOrder?.orderTotal ? (
        <h5 style={{ color: 'red' }}>
          Cannot perform compensation as the compensation amount is greater than the order total amount.
        </h5>
      ) : null}
      <hr />
      {selectOrderLoading ? (
        <Spin size="large" />
      ) : selectedOrder ? (
        <>
          <h4>Previous total refunds: {selectedOrder.totalOrderRefund}</h4>
          <h4>Order original price: {selectedOrder.orderTotal} </h4>
        </>
      ) : null}
    </div>
  );
};

export default CompensationTab;
