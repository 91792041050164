import React, { useState } from 'react';
import { useAxiosGet, useAxiosPost } from '../../../configs/axios';
import { Button, Col, Form, InputNumber, message, Row, Select, Spin, Table } from 'antd';

function DriverCompensate({ selectedOrder }) {
  const { data: agentCompensateData, loading: agentCompensateLoading } =
    useAxiosGet('compensate/driverCompensations', {
      autoRun: true,
      orderId: selectedOrder.orderId,
    });


    const { request: requestCompensate } = useAxiosPost('couriers/compensate', {
      autoRun: false,
    })
    const [submitLoading, setSubmitLoading] = useState(false)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'courier_id',
      key: 'courier_id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: 'Courier name',
      dataIndex: 'courier_name',
      key: 'courier_name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];
  const onCompensateFinish = async (values) => {
    const body = {
      orderId: selectedOrder.orderId,
      amount: values.value,
      compensateOn: values.compensate_on,
    };

    setSubmitLoading(true);

    try {
      await requestCompensate(body);
      message.success('Compensated successfully');

    } catch (error) {
        message.error('Something went wrong');
    } finally{
      setSubmitLoading(false)
    }


  };
  const handleValueChange = (value) =>{
    if (isNaN(value)) {
      message.error('Please Enter a number and must be positive!')
    }
  }


  return (
    <div>
      <Form
        initialValues={{
          value: 2000,
        }}
        onFinish={onCompensateFinish}
        layout="vertical"
      >
        <Row gutter={[10]}>
          <Col span={4}>
            <Form.Item
              name="value"
              label="Compensate value"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
                {
                  type: 'number',
                  min: 0,
                  message: 'Value must be a number and positive!'
                }
              ]}
            >
              <InputNumber
                placeholder="Enter a value"
                style={{ width: '100%' }}
                min = {0}
                onChange={handleValueChange}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="compensate_on"
              label="Compensate on"
              rules={[
                {
                  required: true,
                  message: 'Select a value',
                },
              ]}
            >
              <Select
                placeholder="Select source"
                style={{ width: '100%' }}
                options={[
                  {
                    label: 'Lezzoo',
                    value: 'lezzoo',
                  },
                  {
                    label: 'Merchant',
                    value: 'merchant',
                  },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="primary"
              danger
              htmlType="submit"
              style={{ marginTop: '30px' }}
              loading={submitLoading}

            >
              Compensate
            </Button>
          </Col>
        </Row>
      </Form>
      {submitLoading && (
        <div className="overlay" style={{

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

        }} >
          <Spin size="large"  />
        </div>
      )}

      {agentCompensateLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          loading={agentCompensateLoading}
          dataSource={agentCompensateData}
          columns={columns}
        />
      )}
    </div>
  );
}

export default DriverCompensate;
