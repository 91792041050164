import { useReducer, useRef } from 'react';
import { Button, Col, Form, Modal, Row, Select, message, Tag, DatePicker } from 'antd';
import RemoteSelectForm from '../utility/RemoteSelectForm';
import {
  useAxiosGetTaskManagement,
  useAxiosPostTaskManagement,
} from '../../configs/axios';
import classes from '../TaskBoard/style/task-board-drawer.module.css';
import CustomMention from '../TaskBoard/CustomMention';
import FileUploader from '../utility/FileUploader';


const CreateTicketComponentButton = ({ hideTitle }) => {
  const [form] = Form.useForm();
  const issueSource = Form.useWatch('issue_source', form);
  const department = Form.useWatch('department', form);
  const { request: requestCreateTicket, loading: loadingCreateTicket } =
    useAxiosPostTaskManagement('/OperationTasks/AddTask', {
      autoRun: false,
    });

  const initValue = {
    showCreateTicketModal: false,
  };

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );

  const commentInput = useRef();
  const selectedAgent = useRef([]);

  const { request: sendCommentRequest, loading: sendCommentLoading } =
    useAxiosPostTaskManagement('/OperationTasks/comment', {
      autoRun: false,
    });

  const onFinishTicket = async (values) => {
    try {
      const newBody = {
        issue_source: values.issue_source,
        task_comment: commentInput.current,
        userNames: selectedAgent.current?.map((agent) => agent.value),
        issue_id: values.issue_id?.value,
        issue_name: values.issue_id?.label,
        files:values.file?.map((image) => ({url:image.url,type:image.type})),
        issue_on: values.issue_on,
        priority: values.priority,
        product: values.product,
        // points: values.points,
        city: values.city,
        estimatedTime: values.estimatedTime ? values.estimatedTime.format('YYYY-MM-DD HH:mm:ss') : null

      };
      values?.issue_source?.forEach((source) => {
        newBody[source] = values[source]?.value;
      });
      console.log('newBody:', newBody);
      console.log('values:', values);

      await requestCreateTicket(newBody);
      message.success('Operation successful');
      form.resetFields();
      // setState({
      //   showCreateTicketModal: false,
      // });
    } catch (error) {
      console.error('Error:', error);
      message.error('Something went wrong');
    }
  };

  const onShowModal = () =>
    setState({
      showCreateTicketModal: true,
    });

  const onCloseModal = () => {
    form.resetFields();
    setState({
      showCreateTicketModal: false,
    });
  };

  const showValueOption = {
    merchant: 'merchant_id',
    driver: 'courier_external_id',
    customer: 'customer_id',
    agent: '_id',
  };
  const showUrlOption = {
    merchant: '/merchants/',
    driver: '/couriers/list',
    customer: '/customers/',
    agent: '/users/status',
  };

  const createLabel = {
    merchant: 'Merchant',
    driver: 'Driver',
    customer: 'Customer',
    agent: 'Agent',
  };


  // const productOptions = [

  //   { label: 'B2C', value: 'b2c', color: '#81b29a' },
  //   { label: 'B2B', value: 'b2b', color: '#2a9d8f' },
  //   { label: 'Friendz', value: 'friendz', color: '#f4a261' },
  //   { label: 'POS', value: 'pos', color: '#e76f51' },
  //   { label: 'Games (Trivia)', value: 'games_trivia', color: '#264653' },
  //   { label: 'Rojana', value: 'rojana', color: '#f4a261' },
  //   { label: 'E-Com', value: 'ecom', color: '#2a9d8f' },
  //   { label: 'Carrefour', value: 'carrefour', color: '#e26d5c' },
  //   { label: 'Games (Zorina)', value: 'games_zorina', color: '#457b9d' },
  //   { label: 'Lezzoo Games', value: 'lezzoo_games', color: '#d4a373' },
  //   { label: 'CMS', value: 'cms', color: '#6a994e' },
  //   { label: 'Merchant Video', value: 'merchant_video', color: '#780000' },
  //   { label: 'Tutorial Video', value: 'tutorial_video', color: '#bc6c25' },
  //   { label: 'Others', value: 'others', color: '#8d99ae' },
  //   { label: 'P2B', value: 'p2b', color: '#2b2d42' },
  //   { label: 'PAY BY CARD', value: 'pay_by_card', color: '#3a86ff' },
  //   { label: 'Fib Time', value: 'fib_time', color: '#ffbe0b' },
  //   { label: 'Pay On Lezzoo', value: 'pay_on_lezzoo', color: '#bb3e03' },
  //   { label: 'Districts', value: 'districts', color: '#6d597a' },
  //   { label: 'Vouchers', value: 'vouchers', color: '#03045e' },
  //   { label: 'Games', value: 'games', color: '#7209b7' },
  //   { label: 'International Day', value: 'international_day', color: '#0b2545' },
  //   { label: 'Order On Lezzoo', value: 'order_on_lezzoo', color: '#f94144' },
  //   { label: 'DataOps', value: 'dataops', color: '#90be6d' },
  //   { label: 'Sponsorship', value: 'sponsorship', color: '#f8961e' },
  //   { label: 'Lezzoo Bena', value: 'lezzoo_bena', color: '#43aa8b' },
  //   { label: 'Lezzoo Medicine', value: 'lezzoo_medicine', color: '#3d405b' },

  // ];

  const priorityOptions = [
    { label: 'High', value: 'High', color: '#E63946' },
    { label: 'Medium', value: 'Medium', color: '#F4A261' },
    { label: 'Low', value: 'Low', color: '#00BD9D' }
  ];

  const showLabelOption = (source, record) => {
    switch (source) {
      case 'merchant':
        return `${record.merchant_id} - ${record.merchant_name} - ${record.merchant_city}`;
      case 'driver':
        return `${record.courier_id} - ${record.courier_name} - ${record.courier_city}`;
      case 'customer':
        return `${record.customer_id} - ${record.customer_name} - ${record.customer_city}`;
      case 'agent':
        return `${record.displayname_en}`;
      default:
        return 'value';
    }
  };
  const cityOptions = [

    { label: 'Erbil', value: 'erbil' },
    { label: 'Sulaymaniyah', value: 'sulaymaniyah' },
    { label: 'Duhok', value: 'duhok' },
    { label: 'Halabja', value: 'halabja' },
    { label: 'Kirkuk', value: 'kirkuk' },
    { label: 'Koya', value: 'koya' },
  ]
  const handleUpload = async (options, type) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append(type, file);

    try {
      const response = formData;
      onSuccess('Ok');
      message.success('Upload successful');
      const url = response.data.url;
      setState((prevState) => ({
        ...prevState,
        [`${type}Url`]: [...prevState[`${type}Url`], url],
      }));
    } catch (err) {
      onError({ err });
      message.error('Upload failed');
    }
  };


  return (
    <>
      <Button
        size="large"
        type="primary"
        style={{
          borderRadius: '60px',
        }}
        onClick={onShowModal}
      >
        Create A Ticket
      </Button>
      <Modal
        title="Create Ticket"
        footer={null}
        open={state.showCreateTicketModal}
        onCancel={onCloseModal}
        width={1200}
        destroyOnClose

      >
        <Form
          form={form}
          layout="vertical"
          style={{
            width: '100%',
          }}
          onFinish={onFinishTicket}
        >
          <Row gutter={[12, 4]}>
            <Col span={8}>
              <Form.Item name="issue_source" label="Issue with">
                <Select
                  mode="multiple"
                  onChange={() => {
                    form.resetFields(['field_value', 'issue_id']);
                  }}
                  options={[
                    { value: 'driver', label: 'Driver' },
                    { value: 'merchant', label: 'Merchant' },
                    { value: 'customer', label: 'Customer' },
                    { value: 'agent', label: 'Agent' },
                  ]}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="issue_on" label="Issue On">
                <Select
                  onChange={() => {
                    form.resetFields(['field_value', 'issue_id']);
                  }}
                  options={issueSource?.map((source) => ({
                    value: source,
                    label: source,
                  }))}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <RemoteSelectForm
                labelOption="department_name"
                optionText="department_name"
                valueOption={'department_id'}
                placeholder="department"
                nameForm={'department'}
                allowClear={true}
                filterFromServer={true}
                labelForm={'Department'}
                endpoint="/OperationTasks/departments"
                fetchAxios={useAxiosGetTaskManagement}

              />
            </Col>
            {issueSource?.length > 0 &&
              issueSource.map((source) => (
                <Col span={8} key={source}>
                  <RemoteSelectForm
                    labelForm={createLabel[source]}
                    endpoint={showUrlOption[source]}
                    rules={[
                      {
                        required: true,
                        message: `Please select a ${createLabel[source]}`,
                      },
                    ]}
                    style={{ width: '100%' }}
                    valueOption={showValueOption[source]}
                    optionText={(record) => showLabelOption(source, record)}
                    nameForm={source}
                    allowClear={true}
                    filterFromServer={true}
                    placeholder={`Select a ${createLabel[source]}`}
                    dependency={[source]}
                    query={{ limit: 10 }}
                  />
                </Col>
              ))}

            <Col span={8}>
              <Form.Item>
                <RemoteSelectForm
                  endpoint={'/OperationTasks/issueList'}
                  labelForm="Issue"
                  rules={[
                    {
                      required: true,
                      message: 'Please select an issue',
                    },
                  ]}
                  style={{ width: '100%' }}
                  valueOption="task_issue_id"
                  labelOption="task_issue_name"
                  nameForm="issue_id"
                  allowClear={true}
                  query={{
                    department_id: department?.value,
                  }}
                  dependency={[department?.value]}
                  placeholder="Select an issue"
                  fetchAxios={useAxiosGetTaskManagement}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="comment"
                label='Type your comment Or mention someone by typing "@"'
                style={{ marginBottom: 100 }}
              >
                <div className={classes.commentInputContainer}>
                  <CustomMention
                    comment={commentInput}
                    selectedUser={selectedAgent}
                  />
                </div>
              </Form.Item>
            </Col>


            {/* <Col span={8}>
              <Form.Item
                name="points"
                label="Points"
              >
                <Select placeholder="Select points">
                  {[1, 2, 3, 5, 8, 13, 21].map(value => {
                    const pointColors = {
                      1: '#b0c4b1', // Light Cyan
                      2: '#457B9D', // Desaturated Blue
                      3: '#1D3557', // Dark Slate Blue
                      5: '#F4A261', // Soft Orange
                      8: '#E76F51', // Vibrant Coral
                      13: '#E63946', // Bright Red
                      21: '#512D38', // Light Mint
                    };
                    return (
                      <Option key={value} value={value}>
                        <Tag style={{ backgroundColor: pointColors[value], color: '#fff', borderRadius: '12px' }}>
                          {value}
                        </Tag>

                      </Option>
                    );
                  })}
                </Select>

              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item name="priority" label="Priority">
                <Select placeholder="Select priority">
                  {priorityOptions.map((priority) => (
                    <Option key={priority.value} value={priority.value}>
                      <Tag color={priority.color} style={{ borderRadius: '8px' }}>

                        {priority.label}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item name="product" label="Product">
                <Select placeholder="Select product">
                  {productOptions.map((product) => (
                    <Option key={product.value} value={product.value}>
                      <Tag color={product.color} style={{ borderRadius: '8px' }}>
                        {product.label}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}

            <Col span={8}>
              <Form.Item name="city" label="City">
                <Select placeholder="Select product">
                  {cityOptions.map((city) => (
                    <Option key={city.value} value={city.value}>
                      {city.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="estimatedTime" label="Estimated Time">
                <DatePicker
                  placeholder="Select estimated time"
                  format="YYYY-MM-DD HH:mm"
                  showTime={{ format: 'HH:mm' }} // Shows time picker with hours and minutes
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

           </Row>

          <Row>

            <Col span={24}>
              <Form.Item name="file" label="Files">
                <FileUploader
                  form={form}
                  formName="file"
                  numberOfUpload={10}
                  imageUploader
                />
              </Form.Item>
            </Col>
           
          </Row>




          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreateTicket}
              style={{
                borderRadius: '8px',
                width: '100%',
                backgroundColor: '#FF4D4F',
                color: '#FFF',
              }}
            >
              Create Ticket
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTicketComponentButton;
