import { useHookstate } from '@hookstate/core';
import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import supportTeamMetricsStates from '../States';
import supportTeamMetricsFunctions from '../SupportTeamMetricsFunctions';

function TabList() {
  const tabs = useHookstate(supportTeamMetricsStates.dataState.tabs);  
  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Row
        gutter={[24, 24]}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'rgba(48,48,48,1)',
          alignSelf: 'center',
          alignItems: 'center',
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 10,
        }}
      >
        {tabs.get().map((tab) => (
          <Col key={tab.tab}>
            <Button
              style={{
                backgroundColor:
                  tab.tab ===
                  supportTeamMetricsStates.dataState.selectedTab.get()
                    ? 'white'
                    : 'rgba(91,91,91,1)',
                borderWidth: 0,
                color:
                  tab.tab ===
                  supportTeamMetricsStates.dataState.selectedTab.get()
                    ? 'black'
                    : 'white',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
              }}
              onClick={() => supportTeamMetricsFunctions.selectTab(tab?.tab)}
            >
              {tab.tab}{' '}
              <div
                style={{
                  borderRadius: '50%',
                  backgroundColor:
                    tab?.tab ==
                    supportTeamMetricsStates.dataState.selectedTab.get()
                      ? 'red'
                      : 'rgba(48,48,48,1)',
                  color: 'white',

                  marginLeft: 5,
                  height: 20,
                  width: 20,

                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {tab?.count}
              </div>
            </Button>
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default TabList;
