import { hookstate } from '@hookstate/core';

const dataState = hookstate({
  data: [],
  loading: true,
  error: null,
  selectedTab: 'All',
  filteredData: [],
  tabs: [],
});

const modalState = hookstate({
  visible: false,
  data: null,
});

export const supportTeamMetricsStates = {
  dataState,
  modalState,
};
export default supportTeamMetricsStates;
